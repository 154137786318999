.navbar-toggler{
    display: none;
}
.header.shop .right-bar {
	padding: 5px 0px 0px 0px;
	top: 5px;
}

@media (max-width: 990px) {
    .navbar-toggler {
        display: inline;
    }
}

@media (max-width: 745px) {
    .header.shop .right-bar {
        padding: 5px 0px 0px 0px;
        top: 5px;
    }
}


.header-inner {
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    z-index: 1000;
}

.header-inner.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
