/* =====================================
Template Name: Eshop
Author Name: Naimur Rahman
Author URI: http://www.wpthemesgrid.com/
Description: Eshop - eCommerce HTML5 Template.
Version:1.0
========================================*/
/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 991px) {
.section{
	padding:50px 0px   !important;;
}
.shop.single {
	padding: 30px 0 50px 0  !important;
}
.section-title {
	margin-bottom: 50px  !important;
	padding: 0px 109px  !important;
}
.section-title h2 {
	font-size: 26px   !important;;
	margin-bottom: 20px   !important;;
}
.section-title p {
	font-size: 13px  !important;
}
.shop-services.section {
	padding: 45px 0 0px 0  !important;
}
.breadcrumbs {
	padding: 20px 0  !important;
}
.blog-single.grid .pagination{
	float:none  !important;
	text-align:center  !important;
}
.header.shop .topbar .top-left{
	text-align:center  !important;
}
.header.shop .topbar .right-content {
	text-align: center  !important;
	float: none  !important;
	margin-top: 8px  !important;
}
.header.shop .right-bar .sinlge-bar .single-icon {
	font-size: 18px  !important;
}
.header .shopping .shopping-item {
	top: 62px  !important;
}
.header .shopping .dropdown-cart-header a {
	font-size: 12px  !important;
}
.header .shopping .dropdown-cart-header span {
	font-size: 12px  !important;
}
.header .shopping-list h4 a {
	font-weight: 500  !important;
	font-size: 13px  !important;
}
.header .shopping-item .total span {
	font-size: 12px  !important;
	font-weight: 500  !important;
}
.header .shopping-item .total .total-amount {
	font-size: 13px  !important;
	font-weight: 500  !important;
}
.header .shopping-item .bottom .btn {
	padding: 8px 20px  !important;
}
.header.shop .search-bar {
	/* height: 45px  !important; */
}
.header.shop .nice-select {
	height: 44px  !important;
	line-height: 44px  !important;
	font-size: 13px  !important;
}
.header.shop .nice-select .list li {
	font-size: 13px  !important;
}
.header.shop .search-bar .btnn {
	/* height: 45px  !important;
	line-height: 45px  !important; */
	font-size: 14px  !important;
}
.header.shop .search-bar {
	width: 445px  !important;
}
.header.shop .search-bar input {
	padding: 8px 52px 0 20px  !important;
	width: 264px  !important;
	height: auto  !important;
	font-size: 13px  !important;
}
.header.shop .all-category {
	display: none  !important;
}
.navbar-nav {
	display: inline-block   !important;;
}
.header.shop .nav li {
	display:inline-block  !important;
	margin-right:2px  !important;
}
.header.shop .nav li a {
	font-size: 14px  !important;
	padding: 15px 15px  !important;
}
.header .nav li a i {
	font-size: 8px  !important;
}
.header.shop .nav li .new {
	font-size: 8px  !important;
	left: 21px  !important;
	top: 0px  !important;
	font-weight: 500  !important;
	height: 16px  !important;
	line-height: 16px  !important;
}
.header.shop .nav .dropdown li {
	display:block  !important;
}
.hero-slider {
	height: 416px  !important;
}
.hero-slider .hero-text {
	margin-top: 80px  !important;
}
.hero-slider .hero-text h1 {
	font-size: 35px  !important;
	margin-bottom: 5px  !important;
}
.hero-slider .hero-text h1 span {
	font-size: 17px  !important;
	margin-bottom: 0  !important;
}
.hero-slider .hero-text .btn {
	padding: 12px 30px  !important;
}
.hero-area2 .single-slider {
	height: 400px  !important;
}
.hero-area2 .single-slider .content{
	padding:90px 0px 0px 0px  !important;
}
.hero-area2 .single-slider .content .sub-title {
	font-size: 14px  !important;
}
.hero-area2 .single-slider .content .title {
	font-size: 17px  !important;
	margin-top: 7px  !important;
	margin-bottom: 12px  !important;
}
.hero-area2 .single-slider .content .des {
	color: #fff  !important;
	line-height: 20px  !important;
}
.hero-area2 .single-slider .content .button .btn {
	padding: 7px 18px  !important;
	margin-top: 22px  !important;
	font-size: 12px  !important;
}
.hero-area3 .big-content {
	height: 400px  !important;
	margin-top: 16px  !important;
}
.hero-area3 .big-content .title {
	font-size: 25px  !important;
	margin-bottom: 12px  !important;
	line-height: 35px  !important;
}
.hero-area3 .big-content .button {
	margin-top: 30px  !important;
	display: block  !important;
}
.hero-area3 .big-content .button .btn {
	color: #fff  !important;
	padding: 10px 24px  !important;
}
.hero-area3 .small-content {
	margin-top: 15px  !important;
}
.hero-area3 .small-content.first {
	width: 49%  !important;
	display: inline-block  !important;
	margin-right: 10px  !important;
}
.hero-area3 .small-content.secound {
	width: 49%  !important;
	display: inline-block  !important;
	float: right  !important;
}
.hero-area4 .big-content {
	height: 400px  !important;
	margin-top: 20px  !important;
}
.hero-area4 .big-content .inner {
	padding: 0  !important;
	padding-right: 30px  !important;
}
.hero-area4 .big-content .title {
	font-size: 25px  !important;
	margin-bottom: 20px  !important;
	line-height: 30px  !important;
}
.hero-area4 .big-content .des {
	font-size: 13px  !important;
}
.hero-area4 .big-content .button {
	margin-top: 30px  !important;
}
.hero-area4 .big-content .button .btn {
	padding: 10px 20px  !important;
}
.small-banner {
	padding: 18px 0  !important;	
}
.small-banner .single-banner.tab-height{
	height:300px  !important;
}
.small-banner .single-banner{
	margin-bottom: 18px  !important;
}
.modal-dialog .quickview-slider-active{
	width:720px  !important;
}
.quickview-content {
	padding: 40px  !important;
}
.quickview-content .size .title {
	margin-top: 16px  !important;
}
.midium-banner .single-banner .content {
	padding: 35px  !important;
}
.midium-banner .single-banner h3 {
	font-size: 20px  !important;
	line-height: 24px  !important;
}
.midium-banner .single-banner a {
	padding: 6px 20px  !important;
	margin-top: 15px  !important;
}
.most-popular .single-product {
	margin: 20px 15px 0 15px  !important;
}
.shop-home-list .shop-section-title {
	margin-top: 20px  !important;
}
.cown-down {
	height: auto  !important;
}
.cown-down .padding-left {
	padding: 0  !important;
}
.cown-down .padding-right {
	padding: 0  !important;
}
.cown-down .content {
	padding: 50px 110px  !important;
}
.cown-down .content .heading-block{
	padding: 0  !important;
	position: relative  !important;
}
.shop-blog .shop-single-blog {
	margin-bottom: 30px  !important;
}
.shop-services{
	padding:50px 0  !important;
}
.shop-services .single-service {
	padding: 12px 0 12px 65px  !important;
}
.shop-newsletter .newsletter-inner input {
	width: 380px  !important;
}
.shop-blog.grid .shop-single-blog {
	margin-top:0px  !important;
}
.main-sidebar .side-tags .tag {
	margin-top: 30px  !important;
}
.shopping-cart .table td {
	padding: 20px  !important;
}
.shopping-cart .qty .button .btn {
	width: 38px  !important;
}
.shopping-cart .table .qty{
	padding:0  !important;
}
.shopping-cart .total-amount .left .coupon form .btn {
	display: inline-block  !important;
	height: 48px  !important;
	margin-left: 0px  !important;
	width: 220px  !important;
	margin-top: 10px  !important;
	display:block  !important;
}
.contact-us .form-main {
	padding: 30px  !important;
}
.contact-us .form .button .btn {
	height: auto  !important;
	padding: 15px 22px  !important;
}
#myMap {
	height: 300px  !important;
	width: 100%  !important;
}
.contact-us .single-head {
	padding: 30px  !important;
	margin-top: 30px  !important;
}
.about-us .about-content {
	padding: 0  !important;
	margin-bottom: 30px  !important;
}
.error-page .error-inner h2 {
	font-size: 100px  !important;
}
.footer .copyright .left {
	float:none  !important;
	text-align:center  !important;
}
.footer .copyright .right {
	float:none  !important;
	text-align:center  !important;
	margin-top:10px  !important;
}
.footer .single-footer {
	padding:20px 0  !important;
}





}
/* Mobile Screen */
@media only screen and (max-width: 767px) {
.breadcrumbs {
	padding: 90px 0 90px  !important;
}
.breadcrumbs h2 {
	font-size: 28px  !important;
}
.shop.single {
	padding: 0px 0 30px 0  !important;
}
.shop-services.section {
	padding: 40px 0 0 0  !important;
}
.blog-single.grid .pagination{
	float:none  !important;
	text-align:center  !important;
	margin-top: 12px  !important;
}
.site-header .header-bottom {
	width: 100%  !important;
	z-index: 333  !important;
	position: absolute  !important;
	position: relative  !important;
	padding-bottom: 20px  !important;
	background: #333  !important;
}
.slicknav_menu{
	display:block  !important;
}
.slicknav_menu {
	display: block  !important;
	background: transparent  !important;
	padding: 0  !important;
}
.slicknav_btn {
	background: transparent  !important;
	padding: 0  !important;
	margin: 6px 0 0  !important;
}
.slicknav_menu .slicknav_icon-bar{
	box-shadow:none  !important;
}
.skill-image{
	display:none  !important;
}
.slicknav_nav {
	clear: both  !important;
	color: #fff  !important;
	margin: 0  !important;
	background: transparent  !important;
	padding-top: 15px  !important;
}
.slicknav_nav li a{
	margin: 0  !important;
	color: #333  !important;
	background: transparent  !important;
	overflow: hidden  !important;
}
.slicknav_nav li a i{
	display:none  !important;
}
.slicknav_nav li:hover a{
	color:#F7941D  !important;
	background:transparent  !important;
}
.slicknav_nav li:hover{
	background:transparent  !important;
}
.slicknav_nav li .dropdown li:hover{
	background:transparent  !important;
}
.slicknav_nav li .dropdown li a{
	color:#555  !important;
	background:transparent  !important;
}
.slicknav_nav li .dropdown li a:hover{
	color:#F7941D  !important;
	background:transparent  !important;
}
.slicknav_nav li .slicknav_item{
	background:transparent  !important;
}
.slicknav_nav .slicknav_row, .slicknav_nav a {
	padding: 0  !important;
	margin: 0  !important;
	padding: 6px 0  !important;
	font-weight: 500  !important;
}
.slicknav_nav li:last-child a{
	padding-bottom:0px  !important;
}
.slicknav_menu .slicknav_icon-bar {
	background-color: #F7941D  !important;
	box-shadow: none  !important;
	text-shadow: none  !important;
}
.slicknav_nav .slicknav_arrow{
	color:#F7941D  !important;
}
.slicknav_menu .slicknav_icon-bar {
	display: block  !important;
	width: 25px  !important;
	height: 3px  !important;
	-webkit-border-radius: 1px  !important;
	-moz-border-radius: 1px  !important;
	border-radius: 1px  !important;
	-webkit-box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
	-moz-box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
	box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
}
.slicknav_nav li .slicknav_item:hover{
	background:transparent  !important;
}
.slicknav_nav li .new {
	display:none   !important;;
}
.header.shop .search-bar-top {
	/* display: none  !important; */
}
.header.shop .right-bar {
	/* display: none  !important; */
}
.header .middle-inner{
	width:100%  !important;
}
.header.sticky .middle-inner{
	position:fixed  !important;
	top:0  !important;
	left:0  !important;
	background:#fff  !important;
	animation: fadeInDown 1s both 0.2s  !important;
	-webkit-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3)  !important;
	-moz-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3)  !important;
	box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3)  !important;
	z-index:999  !important;
}
/* Header Search */
.header.shop .search-top {
	display:block  !important;
	position: absolute  !important;
	right: 50px  !important;
	top: 4px  !important;
}
.header.shop .logo {
	margin: 0   !important;;
}
.section{
	padding:50px 0px  !important;
}
.section-title {
	margin-bottom: 30px  !important;
	padding: 0px 20px  !important;
}
.section-title h2 {
	font-size: 22px   !important;;
	margin-bottom: 20px   !important;;
}
.section-title p {
	font-size: 13px  !important;
}
.breadcrumbs {
	padding: 20px 0  !important;
}
.header.shop .topbar .top-left{
	text-align:center  !important;
}
.header.shop .topbar .right-content {
	text-align: center  !important;
	float: none  !important;
	margin-top: 8px  !important;
}
.header.shop .search-bar {
	width: 420px  !important;
}
.header.shop .search-bar input {
	padding: 0px 52px 0 20px  !important;
	width: 264px  !important;
}
.header.shop .all-category {
	display: none  !important;
}
.header .header-inner{
	/* display:none  */
}
.hero-slider {
	height: 350px  !important;
}
.hero-slider .hero-text {
	margin-top: 60px  !important;
}
.hero-slider .hero-text h1 {
	font-size: 30px  !important;
	margin-bottom: 0px  !important;
}
.hero-slider .hero-text h1 span {
	font-size: 15px  !important;
	margin-bottom: 0  !important;
}
.hero-slider .hero-text p {
	color: #333  !important;
	margin-bottom: 28px  !important;
}
.hero-slider .hero-text .btn {
	padding: 10px 25px  !important;
}
.hero-area2 .single-slider {
	height: 400px  !important;
}
.hero-area2 .single-slider .content{
	padding:90px 0px 0px 0px  !important;
}
.hero-area2 .single-slider .content .sub-title {
	font-size: 14px  !important;
}
.hero-area2 .single-slider .content .title {
	font-size: 17px  !important;
	margin-top: 7px  !important;
	margin-bottom: 12px  !important;
}
.hero-area2 .single-slider .content .des {
	color: #fff  !important;
	line-height: 20px  !important;
}
.hero-area2 .single-slider .content .button .btn {
	padding: 7px 18px  !important;
	margin-top: 22px  !important;
	font-size: 12px  !important;
}
.hero-area3 .big-content {
	height: 300px  !important;
	margin-top: 16px  !important;
}
.hero-area3 .big-content .inner {
	padding: 0 25px 0 250px  !important;
}
.hero-area3 .big-content .title {
	font-size: 20px  !important;
	margin-bottom: 9px  !important;
	line-height: 27px  !important;
}
.hero-area3 .big-content .des {
	line-height: 22px  !important;
	font-size: 13px  !important;
}
.hero-area3 .big-content .button {
	margin-top: 30px  !important;
	display: block  !important;
}
.hero-area3 .big-content .button .btn {
	padding: 8px 18px  !important;
	font-size: 13px  !important;
}
.hero-area3 .small-content {
	margin-top: 15px  !important;
}
.hero-area4 .big-content {
	height: 300px  !important;
	margin-top: 20px  !important;
}
.hero-area4 .big-content .inner {
	padding: 0  !important;
	padding-right: 30px  !important;
	background: #ffffffba  !important;
	padding: 25px  !important;
	left: 0  !important;
	height:100%  !important;
}
.hero-area4 .big-content .title {
	font-size: 20px  !important;
	margin-bottom: 12px  !important;
	line-height: 22px  !important;
}
.hero-area4 .big-content .des {
	font-size: 13px  !important;
	color: #333  !important;
	line-height: 21px  !important;
}
.hero-area4 .big-content .button {
	margin-top: 20px  !important;
}
.hero-area4 .big-content .button .btn {
	padding: 6px 17px  !important;
	font-size: 12px  !important;
}
.hero-area4 .owl-carousel .owl-nav div {
	height: 32px  !important;
	width: 35px  !important;
	line-height: 32px  !important;
	font-size: 11px  !important;
	background:#3336  !important;
}
.hero-area4 .owl-carousel .owl-nav {
	margin-top: -16px  !important;
}
.product-area .nav-tabs .nav-item {
	margin-bottom: 10px  !important;
}
.small-banner {
	padding: 18px 0  !important;
	
}
.small-banner .single-banner{
	margin-bottom: 18px  !important;
}
.modal-dialog .quickview-slider-active{
	width:720px  !important;
}
.quickview-content {
	padding: 40px  !important;
}
.quickview-content .size .title {
	margin-top: 16px  !important;
}
.midium-banner .single-banner .content {
	padding: 35px  !important;
}
.midium-banner .single-banner{
	margin-bottom:15px  !important;
}
.midium-banner .single-banner h3 {
	font-size: 20px  !important;
	line-height: 24px  !important;
}
.midium-banner .single-banner a {
	padding: 6px 20px  !important;
	margin-top: 15px  !important;
}
.most-popular .single-product {
	margin: 20px 15px 0 15px  !important;
}
.shop-home-list .shop-section-title {
	margin-top: 20px  !important;
}
.shop-home-list .single-list .content {
	padding: 0  !important;
	padding: 20px  !important;
	text-align: center  !important;
}
.shop.single .product-des .size {
	margin-top: 10px  !important;
}
.shop.single .product-des .short h4 {
	font-size: 20px  !important;
	font-weight: 500  !important;
	margin-top: 0  !important;
	line-height: 24px  !important;
}
.shop.single .product-des {
	margin-top: 20px  !important;
}
.shop.single .product-des .rating {
	margin-top: 6px  !important;
}
.shop.single .product-des .price {
	font-size: 17px  !important;
	margin-top: 10px  !important;
}
.shop.single .product-des .short .description {
	font-size: 14px  !important;
	margin-top: 10px  !important;
	margin-bottom: 10px  !important;
	padding-bottom: 11px  !important;
}
.shop.single .product-des .color h4 {
	font-size: 15px  !important;
	font-weight: 500  !important;
}
.shop.single .add-to-cart .btn {
	height: 42px  !important;
	padding: 0 35px  !important;
	margin-right: 5px  !important;
	margin-top: 6px  !important;
}
.shop.single .form .form-group textarea {
	height: 150px  !important;
}
.shop.single .product-des .color ul li a {
	margin-bottom: 8px  !important;
}
.shop.single .quantity .input-number {
	height: 42px  !important;
}
.shop.single .nav-tabs li a {
	padding: 8px 22px  !important;
}
.shop.single .single-des p {
	font-size: 14px  !important;
	font-weight: 400  !important;
}
.shop.single .single-des {
	margin-top: 18px  !important;
}
.shop.single .single-des ul li {
	margin-bottom: 4px  !important;
}
.shop.single .rating-des {
	padding: 17px 20px 17px 107px  !important;
}
.shop.single .form .form-group button {
	padding: 14px 40px  !important;
}
.cown-down {
	height: auto  !important;
}
.cown-down .content .title {
	font-size: 22px  !important;
}
.cown-down .padding-left {
	padding: 0  !important;
}
.cown-down .padding-right {
	padding: 0  !important;
}
.cown-down .content {
	padding: 30px 20px  !important;
}
.cown-down .content .price {
	margin-top: 22px  !important;
	font-size: 28px  !important;
}
.cown-down .content .price s {
	font-size: 20px  !important;
}
.cown-down .content .heading-block{
	padding: 0  !important;
	position: relative  !important;
}
.shop-blog .shop-single-blog {
	margin-bottom: 30px  !important;
}
.shop-services{
	padding:50px 0  !important;
}
.shop-services .single-service {
	padding: 42px 0 18px 0  !important;
	text-align: center  !important;
}
.shop-services .single-service i {
	height: 40px  !important;
	width: 40px  !important;
	line-height: 40px  !important;
	font-size: 24px  !important;
	top: 0  !important;
	left: 50%  !important;
	margin-left:-20px  !important;
}
.shop-services.home {
	padding: 25px 0  !important;
}
.shop-newsletter .newsletter-inner input {
	width: 330px  !important;
	height: 42px  !important;
	padding:0 20px  !important;
	border-radius: 30px  !important;
	margin-bottom: 10px  !important;
	display:block  !important;
	text-align:center  !important;
}
.shop-newsletter .newsletter-inner .btn {
	height: 42px  !important;
	padding: 10px 15px  !important;
	width: 330px  !important;
	border-radius: 30px  !important;
	left:0  !important;
	display:block  !important;
}
.shop-blog .shop-single-blog .content {
	padding: 28px  !important;
}
.shop-blog.grid .shop-single-blog{
	margin-top:0px  !important;
}
.blog-single .blog-title {
	font-size: 22px  !important;
	margin: 25px 0 15px 0  !important;
}
.blog-single blockquote {
	padding: 20px 30px 20px 65px  !important;
}
.main-sidebar {
	padding: 25px  !important;
}
.main-sidebar .side-tags .tag {
	margin-top: 30px  !important;
}
.blog-single .comments .single-comment.left {
	margin-left: 0  !important;
}
.blog-single .reply form {
	padding: 0  !important;
	border:none  !important;
}
.blog-single .reply .form-group {
	margin-bottom: 15px  !important;
}
.blog-single .reply .form-group input {
	height: 42px  !important;
}
.blog-single .reply .form-group textarea {
	height: 120px  !important;
}
.blog-single .reply .button .btn {
	height: 45px  !important;
	border: none  !important;
	padding: 0px 20px  !important;
}
.cart-information .single-info h4 {
	font-size: 14px  !important;
}
.shopping-summery thead {
	display: none  !important;
}
.shopping-cart .table td {
	padding: .75rem  !important;
	vertical-align: top  !important;
	border-top: 1px solid #e9ecef  !important;
	display: block  !important;
	height: auto  !important;
	text-align: left   !important;;
	padding-left: calc(50% + 20px)   !important;;
	width: 100%  !important;
	position: relative  !important;
	padding-left: 91px  !important;
	margin-top: 5px  !important;
}
.shopping-cart .qty .button .btn {
	padding: 0  !important;
	width: 35px  !important;
}
.shopping-cart .table td::before {
	content: attr(data-title)  !important;
	display: block  !important;
	font-weight: bold  !important;
	position: absolute  !important;
	left: 0px  !important;
	top: 0px  !important;
	padding: 10px 20px  !important;
	-webkit-box-sizing: border-box  !important;
	-moz-box-sizing: border-box  !important;
	box-sizing: border-box  !important;
	width: 50%  !important;
	height: 100%  !important;
	border-bottom: 1px solid #e6ebee  !important;
	background: #F7941D  !important;
	color: #fff  !important;
	border: none  !important;
}
.shopping-cart .table td {
	vertical-align: middle  !important;
	padding: 10px  !important;
	border-bottom: 1px solid #eee  !important;
}
.shopping-summery thead tr th {
	padding: 10px  !important;
}
.shopping-cart .total-amount .right {
	padding-left: 0px  !important;
}
.shopping-cart .total-amount .left {
	margin-bottom: 30px  !important;
}
.shopping-cart .total-amount {
	margin-top: 30px  !important;
}
.shopping-cart .total-amount .left .coupon form .btn {
	display: inline-block  !important;
	height: 48px  !important;
	margin-left: 0px  !important;
	width: 220px  !important;
	margin-top: 10px  !important;
	display:block  !important;
}
.shop.login {
	padding:50px 0  !important;
}
.shop.login .login-form h2 {
	font-size: 30px  !important;
	margin-bottom: 10px  !important;
	padding-bottom: 15px  !important;
}
.pagination .pagination-list li a {
	padding: 4px 12px  !important;
}
.pagination .pagination-list li a {
	padding: 4px 12px  !important;
}
.shop-list .pagination{
	float:none  !important;
	text-align:center  !important;
}
.shop-list .list-content {
	margin-top: 30px  !important;
}
.shop-list .list-content .btn {
	height: auto  !important;
	line-height: auto  !important;
	padding: 12px 32px  !important;
}
.single-product {
	margin-top: 30px  !important;
}
.error-page .error-inner h2 {
	font-size: 55px  !important;
}
.error-page .error-inner h5 {
	font-size: 16px  !important;
	font-weight: 500  !important;
}
.error-page .error-inner p {
	line-height: 20px  !important;
	font-size: 14px  !important;
	padding: 0 20px  !important;
}
.error-page .button .btn {
	padding: 8px 20px  !important;
}
.contact-us .form-main {
	padding: 30px  !important;
}
.contact-us .form .button .btn {
	height: auto  !important;
	padding: 14px 20px  !important;
}
.contact-us .form .form-group input {
	height: 45px  !important;
}
.contact-us .title h3 {
	font-size: 23px  !important;
}
#myMap {
	height: 300px  !important;
	width: 100%  !important;
}
.contact-us .single-head {
	padding: 30px  !important;
	margin-top: 30px  !important;
}
.contact-us .form .form-group input {
	height: 40px  !important;
}
.about-us .about-content {
	padding: 0  !important;
	margin-bottom: 30px  !important;
}
.about-us .about-content h3 {
	font-size: 25px  !important;
	margin-bottom: 12px  !important;
	padding-bottom: 12px  !important;
}
.about-us .about-content p {
	line-height: 22px  !important;
	margin-bottom: 10px  !important;
	font-size: 14px  !important;
	font-weight: 300  !important;
	color:#333  !important;
}
.about-us .about-content .button {
	margin-top: 30px  !important;
}
.about-us .about-content .button .btn {
	margin-right: 10px  !important;
	padding: 10px 22px  !important;
	font-size: 13px  !important;
}
.footer .footer-top{
	padding: 15px 0px  !important;
}
.footer .copyright .left {
	float:none  !important;
	text-align:center  !important;
}
.footer .copyright .right {
	float:none  !important;
	text-align:center  !important;
	margin-top:10px  !important;
}
.footer .single-footer {
	padding:20px 0  !important;
}
.modal-dialog .quickview-slider-active {
	display: none  !important;
}
.modal-dialog .modal-content .modal-header {
	right: 15px  !important;
	top: 12px  !important;
}
.quickview-content {
	padding: 30px  !important;
}
.quickview-content h2 {
	font-size: 22px  !important;
}
.quickview-content h3 {
	font-size: 17px  !important;
	margin: 10px 0 10px  !important;
}
.quickview-content .quickview-peragraph p {
	line-height: 22px  !important;
}
.quickview-content .size .title {
	margin-top:10px  !important;
}
.modal-dialog .modal-content .modal-header .close {
	font-size: 9px  !important;
}
.quickview-content .quantity .input-group {
	margin-bottom: 10px  !important;
}
.quickview-content .add-to-cart .btn {
	height: 42px  !important;
	padding: 0 25px  !important;
	line-height: 42px  !important;
	font-size: 12px  !important;
}
.quickview-content .add-to-cart .btn.min {
	line-height: 41px  !important;
}




}
/* Mobile Screen */
@media only screen and (max-width: 450px) {
.site-header.sticky .header-bottom{
	position:absolute  !important;
	z-index:999  !important;
	top:initial  !important;
	left:0  !important;
	bottom:initial  !important;
}
.site-header .header-bottom {
	width: 100%  !important;
	z-index: 333  !important;
	position: absolute  !important;
	position: relative  !important;
	padding-bottom: 20px  !important;
	background: #333  !important;
}
.slicknav_menu{
	display:block  !important;
}
.slicknav_menu {
	display: block  !important;
	background: transparent  !important;
	padding: 0  !important;
}
.slicknav_btn {
	background: transparent  !important;
	padding: 0  !important;
	margin: 6px 0 0  !important;
}
.slicknav_menu .slicknav_icon-bar{
	box-shadow:none  !important;
}
.skill-image{
	display:none  !important;
}
.slicknav_nav {
	clear: both  !important;
	color: #fff  !important;
	margin: 0  !important;
	background: transparent  !important;
	padding-top: 15px  !important;
}
.slicknav_nav li a{
	margin: 0  !important;
	color: #333  !important;
	background: transparent  !important;
	overflow: hidden  !important;
}
.slicknav_nav li a i{
	display:none  !important;
}
.slicknav_nav li:hover a{
	color:#F7941D  !important;
	background:transparent  !important;
}
.slicknav_nav li:hover{
	background:transparent  !important;
}
.slicknav_nav li .dropdown li:hover{
	background:transparent  !important;
}
.slicknav_nav li .dropdown li a{
	color:#555  !important;
	background:transparent  !important;
}
.slicknav_nav li .dropdown li a:hover{
	color:#F7941D  !important;
	background:transparent  !important;
}
.slicknav_nav li .slicknav_item{
	background:transparent  !important;
}
.slicknav_nav .slicknav_row, .slicknav_nav a {
	padding: 0  !important;
	margin: 0  !important;
	padding: 6px 0  !important;
	font-weight: 500  !important;
}
.slicknav_nav li:last-child a{
	padding-bottom:0px  !important;
}
.slicknav_menu .slicknav_icon-bar {
	background-color: #F7941D  !important;
	box-shadow: none  !important;
	text-shadow: none  !important;
}
.slicknav_nav .slicknav_arrow{
	color:#F7941D  !important;
}
.slicknav_menu .slicknav_icon-bar {
	display: block  !important;
	width: 25px  !important;
	height: 3px  !important;
	-webkit-border-radius: 1px  !important;
	-moz-border-radius: 1px  !important;
	border-radius: 1px  !important;
	-webkit-box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
	-moz-box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
	box-shadow: 0 1px 0 rgba(0,0,0,.25)  !important;
}
.slicknav_nav li .slicknav_item:hover{
	background:transparent  !important;
}
.shop.single {
	padding: 20px 0 30px 0  !important;
}
.header.shop .search-bar-top {
	/* display: none  !important; */
}
.header.shop .right-bar {
	/* display: none  !important; */
}
/* Header Search */
.header.shop .search-top {
	display:block  !important;
	position: absolute  !important;
	right: 50px  !important;
	top: 4px  !important;
}
.header.shop .logo {
	margin: 0   !important;;
}
.section{
	padding:50px 0px  !important;
}
.section-title {
	margin-bottom: 30px  !important;
	padding: 0px 20px  !important;
}
.section-title h2 {
	font-size: 22px   !important;;
	margin-bottom: 20px   !important;;
}
.section-title p {
	font-size: 13px  !important;
}
.breadcrumbs {
	padding: 20px 0  !important;
}
.header.shop .topbar {
	background: #fff  !important;
}
.header.shop .topbar .top-left{
	text-align:center  !important;
}
.header.shop .topbar .right-content {
	text-align: center  !important;
	float: none  !important;
	margin-top: 8px  !important;
}
.header.shop .list-main li {
	border: none  !important;
	padding: 0  !important;
	display: block  !important;
	padding: 5px 0  !important;
	border-bottom: 1px solid #eee  !important;
	text-align: left  !important;
	color: #333  !important;
}
.header.shop.v3 .topbar .inner-content {
	border:none  !important;
	padding: 0  !important;
}
.header.shop .list-main li a {
	color: #333  !important;
}
.header.shop .list-main li a:hover{
	color:#fff  !important;
	text-decoration:underline  !important;
}
.header.shop .top-left .list-main li i {
	color: #fff  !important;
}
.header.shop .list-main li i {
	color: #fff  !important;
}
.header.shop .topbar .right-content {
	margin-top: 0px  !important;
	border-top: 1px solid #ffffff87  !important;
}
.header.shop .search-bar {
	width: 340px  !important;
}
.header.shop .search-bar input {
	padding: 0px 52px 0 20px  !important;
	width: 264px  !important;
}
.header.shop .all-category {
	display: none  !important;
}
.header .header-inner{
	/* display:none  */
}
.header.shop .nav li .new {
	display:none   !important;;
}
.hero-slider {
	height: 350px  !important;
}
.hero-slider .hero-text {
	margin-top: 60px  !important;
}
.hero-slider .hero-text h1 {
	font-size: 30px  !important;
	margin-bottom: 0px  !important;
}
.hero-slider .hero-text h1 span {
	font-size: 15px  !important;
	margin-bottom: 0  !important;
}
.hero-slider .hero-text p {
	color: #333  !important;
	margin-bottom: 28px  !important;
}
.hero-slider .hero-text .btn {
	padding: 10px 25px  !important;
}
.hero-area3 .big-content .inner {
	padding:30px  !important;
}
.hero-area3 .small-content {
	height: 210px  !important;
}
.hero-area3 .small-content .title {
	font-size: 16px  !important;
}
.hero-area3 .small-content .button .btn {
	font-size: 12px  !important;
}
.product-area .nav-tabs .nav-item {
	margin-bottom: 10px  !important;
}
.small-banner {
	padding: 18px 0  !important;
	
}
.small-banner .single-banner{
	margin-bottom: 18px  !important;
}
.small-banner .single-banner p {
	font-size: 13px  !important;
}
.small-banner .single-banner .content {
	padding: 12px 10px 10px 35px  !important;
}
.small-banner .single-banner h3 {
	font-size: 17px  !important;
}
.small-banner .single-banner a {
	margin-top: 15px  !important;
	font-size: 11px  !important;
}
.midium-banner .single-banner .content {
	padding: 15px 10px 10px 30px  !important;
}
.midium-banner .single-banner{
	margin-bottom:15px  !important;
}
.midium-banner .single-banner h3 {
	font-size: 17px  !important;
	line-height: 22px  !important;
}
.midium-banner .single-banner a {
	padding: 5px 20px  !important;
	margin-top: 15px  !important;
	font-size: 12px  !important;
}
.most-popular .single-product {
	margin: 20px 15px 0 15px  !important;
}
.shop-home-list{
	padding-top:0  !important;
}
.shop-home-list .shop-section-title {
	margin-top: 20px  !important;
}
.shop-home-list .single-list .content {
	padding: 0  !important;
	padding: 20px  !important;
	text-align: center  !important;
}
.cown-down {
	height: auto  !important;
}
.cown-down .content .title {
	font-size: 20px  !important;
}
.cown-down .padding-left {
	padding: 0  !important;
}
.cown-down .padding-right {
	padding: 0  !important;
}
.cown-down .content {
	padding: 30px 20px  !important;
}
.cown-down .content .price {
	margin-top: 22px  !important;
	font-size: 25px  !important;
}
.cown-down .content .price s {
	font-size: 20px  !important;
}
.cown-down .content .heading-block{
	padding: 0  !important;
	position: relative  !important;
}
.shop-blog .shop-single-blog {
	margin-bottom: 30px  !important;
}
.shop-services{
	padding:50px 0  !important;
}
.shop-services .single-service {
	padding: 42px 0 18px 0  !important;
	text-align: center  !important;
}
.shop-services .single-service i {
	height: 40px  !important;
	width: 40px  !important;
	line-height: 40px  !important;
	font-size: 24px  !important;
	top: 0  !important;
	left: 50%  !important;
	margin-left:-20px  !important;
}
.shop-services.home {
	padding: 25px 0  !important;
}
.shop-newsletter .newsletter-inner input {
	width: 100%  !important;
	height: 42px  !important;
	padding: 0 12px 0 15px  !important;
	border-radius: 30px  !important;
	margin-bottom: 10px  !important;
	display:block  !important;
	text-align:center  !important;
}
.shop-newsletter .newsletter-inner .btn {
	height: 42px  !important;
	padding: 10px 15px  !important;
	width: 100%  !important;
	border-radius: 30px  !important;
	left:0  !important;
	display:block  !important;
}
.shop-blog .shop-single-blog .content {
	padding: 28px  !important;
}
.shop-blog.grid .shop-single-blog {
	margin-top:0px  !important;
}
.shop.checkout .checkout-form h2 {
	font-size: 20px  !important;
	font-weight:600  !important;
}
.shop.checkout .checkout-form p {
	font-size: 14px  !important;
	font-weight: 400  !important;
	margin-top: 5px  !important;
	margin-bottom: 25px  !important;
}
.shop.checkout .single-widget.get-button .btn {
	height:auto  !important;
	font-size: 13px  !important;
	padding: 12px  !important;
}
.shop.login .login-form h2 {
	font-size: 24px  !important;
	margin-bottom: 8px  !important;
	padding-bottom: 12px  !important;
}
.shop.login .login-form p {
	font-size: 13px  !important;
	margin-bottom: 30px  !important;
	line-height: 18px  !important;
}
.shop.login .form .btn {
	width: 100%  !important;
	text-align: center  !important;
	margin-bottom: 10px  !important;
}
.shop.login .login-form .lost-pass {
	margin-left: 0  !important;
}
.shop .single-shorter label {
	margin: 8px 5px 0 0  !important;
}
.shop .nice-select {
	width: 100%  !important;
	display:   !important;
	margin-top: 4px  !important;
}
.shop .view-mode {
	float: right  !important;
	float: none  !important;
	display: block  !important;
	width: 100%  !important;
}
.shop .view-mode li {
	display: inline-block  !important;
	margin-right: 5px  !important;
	display: inline-block  !important;
	margin: 0  !important;
	mar0-t: mar  !important;
	margin-top: 4px  !important;
	width: 100%  !important;
	display: block  !important;
	display: inline-block  !important;
	display:   !important;
}
.shop .view-mode li a {
	width: 80px  !important;
	height: 32px  !important;
	background: transparent  !important;
	border: 1px solid #77777775  !important;
	text-align: center  !important;
	display: block  !important;
	line-height: 32px  !important;
	color: #888  !important;
	border-radius: 0px  !important;
	display: inline-block  !important;
	margin-bottom: 10px  !important;
}
.shop.single .rating-author {
	margin-right:0  !important;
}
.shop.single .rating-des {
	padding: 17px 20px 17px 95px  !important;
}
.blog-single .blog-title {
	font-size: 20px  !important;
	margin: 20px 0 10px 0  !important;
}
.blog-single blockquote {
	padding: 15px 15px 15px 60px  !important;
}
.blog-single blockquote i {
	font-size: 25px  !important;
}
.main-sidebar {
	padding: 25px  !important;
}
.main-sidebar .side-tags .tag {
	margin-top: 30px  !important;
}
.blog-single .comments .single-comment.left {
	margin-left: 0  !important;
}
.blog-single .reply form {
	padding: 0  !important;
	border:none  !important;
}
.blog-single .reply .form-group {
	margin-bottom: 15px  !important;
}
.blog-single .reply .form-group input {
	height: 42px  !important;
}
.blog-single .reply .form-group textarea {
	height: 120px  !important;
}
.blog-single .reply .button .btn {
	height: 45px  !important;
	border: none  !important;
	padding: 0px 20px  !important;
	width: 100%  !important;
}
.pagination .pagination-list li a {
	padding: 4px 12px  !important;
}
.blog-single.grid .pagination{
	float:none  !important;
	text-align:center  !important;
	margin-top: 12px  !important;
}
.error-page .error-inner h2 {
	font-size: 55px  !important;
}
.error-page .error-inner h5 {
	font-size: 16px  !important;
	font-weight: 500  !important;
}
.error-page .error-inner p {
	line-height: 20px  !important;
	font-size: 14px  !important;
	padding: 0 20px  !important;
}
.error-page .button .btn {
	padding: 8px 20px  !important;
}
.contact-us .title h4 {
	font-size: 15px  !important;
}
.contact-us .title h3 {
	font-size: 18px  !important;
}
.contact-us .form-main {
	padding:0px  !important;
	border:none  !important;
	box-shadow:none  !important;
}
.contact-us .single-head {
	padding:0px  !important;
	border:none  !important;
	box-shadow:none  !important;
	margin-top: 40px  !important;
}
.contact-us .single-info {
	text-align: center  !important;
}
.contact-us .single-info i {
	display: inline-block  !important;
}
.contact-us .single-info .title {
	font-size: 16px  !important;
}
.contact-us .form .form-group textarea {
	height: 150px  !important;
}
.contact-us .form .button .btn {
	width: 100%  !important;
}
.mail-success .mail-inner h2 {
	font-size: 22px  !important;
}
.mail-success .mail-inner p {
	line-height: 21px  !important;
	font-size: 13px  !important;
}
.mail-success .mail-inner .btn {
	padding: 8px 28px  !important;
}
.footer .footer-top{
	padding: 12px 0px  !important;
}
.footer .links ul li {
	margin-bottom: 5px  !important;
}
.footer .single-footer h4 {
	margin-bottom: 12px  !important;
}
.footer .copyright .left {
	float:none  !important;
	text-align:center  !important;
}
.footer .copyright .right {
	float:none  !important;
	text-align:center  !important;
	margin-top:10px  !important;
}
.footer .single-footer {
	padding:12px 0  !important;
}
.modal-dialog .quickview-slider-active {
	display: none  !important;
}
.modal-dialog .modal-content .modal-header {
	right: 15px  !important;
	top: 12px  !important;
}
.quickview-content {
	padding: 30px  !important;
}
.quickview-content h2 {
	font-size: 22px  !important;
}
.quickview-content h3 {
	font-size: 17px  !important;
	margin: 10px 0 10px  !important;
}
.quickview-content .quickview-peragraph p {
	line-height: 22px  !important;
}
.quickview-content .size .title {
	margin-top:10px  !important;
}
.modal-dialog .modal-content .modal-header .close {
	font-size: 9px  !important;
}
.quickview-content .quantity .input-group {
	margin-bottom: 10px  !important;
}
.quickview-content .add-to-cart .btn {
	height: 42px  !important;
	padding: 0 25px  !important;
	line-height: 42px  !important;
	font-size: 12px  !important;
	margin-bottom: 8px  !important;
}
.quickview-content .add-to-cart .btn.min {
	line-height: 41px  !important;
}
.quickview-content .nice-select {
	height: 40px  !important;
}
.quickview-content .quantity .input-number {
	height: 40px  !important;
}
.quickview-content h2 {
	font-size: 18px  !important;
	font-weight: 500  !important;
}
.quickview-content .quickview-peragraph p {
	line-height: 21px  !important;
	font-size: 13px  !important;
	font-weight: 400  !important;
}
.quickview-content h3 {
	font-size: 15px  !important;
	margin: 10px 0 10px  !important;
}
}