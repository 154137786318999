 
  /* Position the arrows closer to the edges of the carousel */
.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
  top: 50%; 
  transform: translateY(-50%); 
  color: #fff;  
  border-radius: 50%; 
}

/* Move the left arrow closer to the left edge */
.slick-prev {
  left: 10px;
}

/* Move the right arrow closer to the right edge */
.slick-next {
  right: 10px;
}

/* Optional: Remove the background color on hover for a cleaner look */
 

/* Adjust arrow size and other styles as needed */
.slick-prev:before, .slick-next:before {
  font-size: 20px;  
  line-height: 40px; 
}


@media (max-width: 767px) {
  .slick-dots {
      margin-top: 10px ;
      margin-bottom: 10px ;
  }
}